import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from 'react-bootstrap'

import Accordion from '../components/Accordion/Accordion'
import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import Seo from '../components/Seo/Seo'

export default function AboutUs() {
  const data = useStaticQuery(query)
  const seo = data.wpPage.seo

  const Header = {
    heading: 'O nas',
    paragraphs: [
      'Jesteśmy firmą, która skupia się wokół idei szerzenia rzetelnej i przejrzystej wiedzy o ubezpieczeniach na życie. W naszym zespole działają osoby związane z branżą marketingową, sprzedażową czy IT. Wszyscy pracujemy nad tym, by stworzyć dla naszych klientów internetową przestrzeń z wartościowymi treściami dotyczącymi ubezpieczeń na życie, której naszym zdaniem brakowało. Chcemy pomagać w podejmowaniu trafnych decyzji - mamy doświadczenie, dzięki któremu możemy to robić.',
      'Pracujemy dla Was w naszej wrocławskiej siedzibie, jednak cały zespół LeadGuru jest rozproszony po całej Polsce. Niezależnie od tego, gdzie się znajdujemy, przyświeca nam ta sama idea - wartościowego doradztwa w zakresie ubezpieczeń na życie.',
    ],
  }
  const HowEarn = {
    heading: 'Jak zarabia LeadGuru?',
    paragraphs: [
      'Świadczymy dla naszych użytkowników bezpłatne usługi pośrednictwa - jesteśmy finansowani przez Reklamodawców, którzy chcą poznać potrzeby osób zainteresowanych ubezpieczeniem. Najpierw określamy, czego oczekujesz, dopasowujemy najlepszą dla Ciebie ofertę i kontaktujemy Cię z wybranym Towarzystwem Ubezpieczeniowym. Za to jesteśmy wynagradzani.',
      'Nie ma to jednak wpływu na cenę polisy czy jakość oferty. Co to oznacza? Szukając ubezpieczenia na życie na własną rękę, otrzymasz u każdego z ubezpieczycieli taką samą ofertę. Naszą rolą jest pomóc w wyborze najlepszego rozwiązania.',
    ],
  }
  const questionsAnswers = [
    {
      question: 'Czy w rankingu są wszyscy ubezpieczyciele?',
      answer:
        'W Rankingu zostali uwzględnieni najpopularniejsi ubezpieczycieli w kraju. Ze względu na ograniczone możliwości, nie każde TU znajduje się w rankingu. Systematycznie pracujemy jednak nad tym, by nasze zestawienie było możliwie jak najpełniejsze.',
    },
    {
      question: 'Co oznaczają miejsca rankingowe?',
      answer: `Miejsca rankingowe tworzone są na podstawie algorytmu, którego dokładne działanie, można poznać ${(
        <Link to="/jak-to-dziala/">tutaj</Link>
      )}. Im wyżej firma znajduje się w rankingu, tym lepiej została oceniona przez klientów. Pobieramy dane pozostawione w sieci przez użytkowników na portalach Facebook, Google, PanoramaFirm.pl, Opinie24.pl, GoWork.pl i Absolvent.pl i na ich podstawie tworzymy ocenę końcową. Im jest ona wyższa, tym wyżej w rankingu znajduje się poszczególne TU. Maksymalna ocena wynosi 10, a minimalna 1.`,
    },
    {
      question: 'Czy Ranking jest rekomendacją ubezpieczenia się w Towarzystwie Ubezpieczeń?',
      answer: `Nie, ranking nie został stworzony po to, by przekonać Cię do zakupu polisy w konkretnych TU. Jest on wiarygodnym zestawieniem na podstawie opinii użytkowników - nie mamy wpływu na to, która firma znajdzie się wyżej w rankingu. Jest to zależne od tego, jak TU oceniane jest przez klientów. Dzięki temu dowiesz się, z której oferty firmy warto skorzystać.<br><br>
      Aby zawrzeć umowę, musisz podać kilka informacji - m.in. numer i serię dowodu osobistego, adres zamieszkania czy nr PESEL. Ubezpieczając się na życie, wskażesz też osoby uposażone (małżonka/i oraz dzieci), które otrzymają finansowe wsparcie, w razie Twojej śmierci, dlatego ich dane również będą potrzebne.`,
    },
    {
      question: 'Dlaczego warto korzystać z Rankingu Ubezpieczeń?',
      answer: `Takie zestawienie jest doskonałą pomocą w wyborze odpowiedniej polisy na życie. Ranking pozwala po pierwsze poznać poszczególnych ubezpieczycieli i dowiedzieć się, z których usług TU klienci są najbardziej zadowoleni. Jest to cenna wskazówka, która warto uwzględnić, szukają ubezpieczenia. Po drugie, takie zestawienie jest ułatwieniem podjęcia trafnej decyzji - oszczędzasz czas, ponieważ nie musisz przeszukiwać informacji o poszczególnych ubezpieczycielach. Po trzecie, możesz liczyć na profesjonalną pomoc w wyborze odpowiedniej polisy - wystarczy, że się do nas odezwiesz, a my doradzimy, która oferta będzie najlepiej spełniała Twoje potrzeby i skontaktujemy Cię bezpośrednio z TU. To z pewnością skróci i uprości cały proces - decyzja należy do Ciebie, jednak pomożemy Ci podjąć ją tak, by była trafiona.`,
    },
    {
      question: 'Jaką ochronę zapewnia ubezpieczenie na życie?',
      answer: `W podstawowym wariancie polisa zabezpiecza bliskich ubezpieczonego na wypadek jego śmierci. Opłacanie składek gwarantuje, że ubezpieczyciel wypłaci wskazanym w umowie osobom konkretną kwotę pieniędzy. Można jednak rozszerzyć zakres ubezpieczenia np. o ochronę w razie trwałej niezdolności do pracy czy inwalidztwa. Ubezpieczony ma zazwyczaj możliwość poszerzenia zakresu ubezpieczenia również na wypadek doznania trwałego uszczerbku na zdrowiu, pobytu w szpitalu, leczenia czy rehabilitacji. Zakres ochrony ubezpieczeniowej może obejmować już nie tylko życie ubezpieczonego, ale również jego zdrowie.`,
    },
    {
      question: 'Dla kogo jest ubezpieczenie na życie?',
      answer: `Dla wszystkich, którzy chcą zapewnić swoim bliskim wsparcie, gdyby ich zabrakło. Ubezpieczenie na życie gwarantuje, że w razie Twojej śmierci, osobom uposażonym zostanie wypłacona konkretna suma, która pozwoli im lepiej poradzić sobie z nieoczekiwaną stratą - pokryć bieżące wydatki czy uregulować zobowiązania. Istotne jest, by suma ubezpieczenia (kwota wypłacona po śmierci ubezpieczonego) odpowiadała potrzebom rodziny.`,
    },
  ]
  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl} />
      <Hero
        subheading={Header.subheading}
        heading={Header.heading}
        paragraphs={Header.paragraphs}
        img={
          <StaticImage
            src="../assets/images/about/about.png"
            loading="eager"
            width={650}
            quality={90}
            placeholder="none"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
      <Container style={{ boxShadow: ' 0px 3px 6px rgb(0 0 0 / 8%)', borderRadius: 6 }}>
        <Accordion questionsAnswers={questionsAnswers} />
      </Container>
      <Hero
        subheading={HowEarn.subheading}
        heading={HowEarn.heading}
        paragraphs={HowEarn.paragraphs}
        reverse={true}
        img={
          <StaticImage
            src="../assets/images/about/how-work.png"
            loading="eager"
            width={650}
            quality={90}
            placeholder="blurred"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
      <Container>
        <LastPosts />
      </Container>
    </Layout>
  )
}

const query = graphql`
  {
    wpPage(slug: { eq: "o-nas" }) {
      seo {
        metaDesc
        title
        focuskw
        opengraphUrl
      }
    }
  }
`